body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(max(240px,20vw), 1fr));
  grid-gap: 24px 40px;
}

.more {
  grid-column: 1 / -1;
}

tr:nth-child(even) {
  background-color: #f5f5f5;
}

@tailwind base;
@tailwind components;
@tailwind utilities;